var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"font-weight-bolder mb-2 hGoBack"},[_c('span',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-button',{staticClass:"btn-icon rounded-circle mr-50 text-danger bg-white",staticStyle:{"padding":"0.5px"},attrs:{"variant":"white"}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftCircleIcon","size":"35"}})],1)],1),_vm._v(" Registrar Incidencia ")]),_c('b-card',[_c('validation-observer',{ref:"createIncidence"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Incidencia',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Incidencia',"label-for":"client-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":'Incidencia'},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":'Fecha',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Fecha',"label-for":"date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"name":"date"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-form-group',{attrs:{"label":'Descripción',"label-for":"product-description"}},[_c('quill-editor',{model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":'Cliente',"label-for":"client"}},[_c('BaseSelect',{attrs:{"resource":'/clients',"value":_vm.client,"label":'name',"placeholder":'Cliente',"clearable":!_vm.readOnlyMode,"readonly":_vm.readOnlyMode},on:{"input":function($event){[
                  _vm.client = $event, 
                  _vm.selectProjectsKey = new Date(),
                  _vm.project = '',
                  _vm.maintenance = '',
                  _vm.asset = '' ]}}})],1),_c('b-form-group',{attrs:{"label":'Obra',"label-for":"project"}},[_c('BaseSelect',{key:("" + _vm.selectProjectsKey),attrs:{"resource":'/projects',"value":_vm.project,"label":'name',"placeholder":'Obra',"httpBody":{ id_client: _vm.clientId },"clearable":!_vm.readOnlyMode,"readonly":_vm.readOnlyMode || _vm.disabledProjectsSelect},on:{"input":function($event){[
                  _vm.project = $event, 
                  _vm.selectMaintenancesKey = new Date(), 
                  _vm.selectAssetsKey = new Date(),
                  _vm.maintenance = '',
                  _vm.asset = '' ]}}})],1),_c('b-form-group',{attrs:{"label":'Mantenimiento',"label-for":"maintenance"}},[_c('BaseSelect',{key:("" + _vm.selectMaintenancesKey),attrs:{"resource":'/maintenances',"value":_vm.maintenance,"label":'name',"placeholder":'Mantenimiento',"httpBody":{ project_id: _vm.projectId },"clearable":!_vm.readOnlyMode,"readonly":_vm.readOnlyMode || _vm.disabledProjectDependentSelects},on:{"input":function($event){_vm.maintenance = $event}}})],1),_c('validation-provider',{attrs:{"name":'Equipo',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Equipo',"label-for":"asset"}},[_c('BaseSelect',{key:("" + _vm.selectAssetsKey),attrs:{"resource":'/assets',"value":_vm.asset,"label":'name',"placeholder":'Equipo',"httpBody":{  
                    client: _vm.clientId,
                    maintenance_id: _vm.maintenanceId,
                    project_id: _vm.projectId, 
                  },"clearable":!_vm.readOnlyMode,"readonly":_vm.disabledProjectDependentSelects},on:{"input":function($event){_vm.asset = $event}}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Documentos',"label-for":"documents"}},[_c('ImageDropzone',{ref:"documents",attrs:{"files":_vm.files}})],1)],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }