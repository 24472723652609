<template>
  <div>
    <h2 class="font-weight-bolder mb-2 hGoBack">
      <span @click="$router.go(-1)">
        <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
          <feather-icon icon="ArrowLeftCircleIcon" size="35" />
        </b-button>
      </span> Registrar Incidencia
    </h2>
    <b-card>
      <!-- form -->
      <validation-observer ref="createIncidence">
        <b-form class="mt-2" @submit.prevent="handleSubmit" @keydown.enter="handleSubmit">
          <b-row>
            <b-col sm="6">
              <!-- name -->
              <validation-provider #default="{ errors }" :name="'Incidencia'" rules="required">
                <b-form-group :label="'Incidencia'" label-for="client-name">
                  <b-form-input v-model="name" name="name" :placeholder="'Incidencia'" />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <!-- date -->
              <validation-provider #default="{ errors }" :name="'Fecha'" rules="required">
                <b-form-group :label="'Fecha'" label-for="date">
                  <flat-pickr v-model="date" name="date" class="form-control" />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <!-- description -->
              <b-form-group :label="'Descripción'" label-for="product-description">
                <quill-editor v-model="description" />
              </b-form-group>

            </b-col>
            <b-col cols="6">
              <!-- client -->
              <b-form-group :label="'Cliente'" label-for="client">
                <BaseSelect
                  :resource="'/clients'"
                  @input="[
                    client = $event, 
                    selectProjectsKey = new Date(),
                    project = '',
                    maintenance = '',
                    asset = '',
                  ]"
                  :value="client"
                  :label="'name'"
                  :placeholder="'Cliente'"
                  :clearable="!readOnlyMode"
                  :readonly="readOnlyMode"
                />
              </b-form-group>
              <!-- project -->
              <b-form-group :label="'Obra'" label-for="project">
                <BaseSelect
                  :key="`${selectProjectsKey}`"
                  :resource="'/projects'"
                  @input="[
                    project = $event, 
                    selectMaintenancesKey = new Date(), 
                    selectAssetsKey = new Date(),
                    maintenance = '',
                    asset = '',
                  ]"
                  :value="project"
                  :label="'name'"
                  :placeholder="'Obra'"
                  :httpBody="{ id_client: clientId }"
                  :clearable="!readOnlyMode"
                  :readonly="readOnlyMode || disabledProjectsSelect"
                />
              </b-form-group>
              <!-- maintenance -->
              <b-form-group  :label="'Mantenimiento'" label-for="maintenance">
                <BaseSelect
                  :key="`${selectMaintenancesKey}`"
                  :resource="'/maintenances'"
                  @input="maintenance = $event"
                  :value="maintenance"
                  :label="'name'"
                  :placeholder="'Mantenimiento'"
                  :httpBody="{ project_id: projectId }"
                  :clearable="!readOnlyMode"
                  :readonly="readOnlyMode || disabledProjectDependentSelects"
                />
              </b-form-group>

              <!-- selectAssets -->
              <validation-provider #default="{ errors }" :name="'Equipo'" rules="required">
                <b-form-group :label="'Equipo'" label-for="asset">
                  <BaseSelect
                    :key="`${selectAssetsKey}`"
                    :resource="'/assets'"
                    @input="asset = $event"
                    :value="asset"
                    :label="'name'"
                    :placeholder="'Equipo'"
                    :httpBody="{  
                      client: clientId,
                      maintenance_id: maintenanceId,
                      project_id: projectId, 
                    }"
                    :clearable="!readOnlyMode"
                    :readonly="disabledProjectDependentSelects"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-col>

            <b-col cols="12">
              <b-form-group :label="'Documentos'" label-for="documents">
                <ImageDropzone ref="documents" :files="files" />
              </b-form-group>
            </b-col>
            <b-col cols="12 text-right">
              <b-button type="submit" variant="primary" class="mt-2 mr-1">
                Guardar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BLink, BFormCheckboxGroup, BFormCheckbox } from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'
import BaseSelect from '@/components/ui/select/BaseSelect.vue';

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    ImageDropzone,
    BLink,
    quillEditor,
    vSelect,
    flatPickr,
    BaseSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      user: '',
      description: '',
      date: '',
      files: [],
      maintenance: '',
      project: '',
      client: '',
      asset: '',
      required,
      readOnlyMode: false,
      selectAssetsKey: '',
      selectProjectsKey: '',
      selectMaintenancesKey: '',
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getUser',
      maintenanceStore: 'maintenances/getMaintenance',
      tecnicos: 'users/getItems',
    }),
    disabledProjectsSelect() {
      return !this.client?.id
    },
    disabledProjectDependentSelects() {
      return !this.project?.id
    },
    clientId() {
      return this.client?.id
    },
    projectId() {
      return this.project?.id
    },
    maintenanceId() {
      return this.maintenance?.id
    },
  },
  methods: {
    ...mapActions({
      create: 'incidences/create',
      getMaintenance: 'maintenances/getMaintenance',
      listTecnicos: 'users/getListUsers',
    }),
    handleSubmit() {
      this.$refs.createIncidence.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.create({ incidence: formData })
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end', 
          });
        }
      })
    },
    createFormData() {
      const data = this.$refs.documents.getFormData('documents')

      data.append('name', this.name)

      if (this.description !== '' || this.description !== null) {
        data.append('description', this.description)
      }

      if (this.date) {
        data.append('date', FlatPickrToTimestamp(this.date))
      }

      if (this.maintenance) {
      data.append('maintenance_id', this.maintenance.id)
      }
      if (this.asset) {
        data.append('asset_id', this.asset.id)
      }

  
      return data
    },
  },
  async created() {
    if (this.$route.params.maintenance_id) {
      this.readOnlyMode = true
      await this.getMaintenance(this.$route.params.maintenance_id)

      this.client = this.maintenanceStore.client
      this.project = this.maintenanceStore.project
      this.maintenance = this.maintenanceStore
    } else {
      this.readOnlyMode = false
    }


  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
